@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

  .layout-nav {
    @apply mx-auto w-11/12 laptop:max-w-full desktop:max-w-7xl
  }
  
  .layout {
    @apply mx-auto w-10/12 laptop:max-w-full desktop:max-w-7xl
  }

  @font-face {
    font-family: 'MyriadPro-Regular';
    src: url('/public/fonts/MyriadPro-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'MyriadPro-Bold';
    src: url('/public/fonts/MyriadPro-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
